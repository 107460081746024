<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <div class="app__inner" [class.anim--in]="loading$()" [@fadeAnimation]="outlet.activatedRouteData">
    @if (loaded$()) {
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [topbarMenu]="'hoofdnavigatie-balk' | fromMenu"
        [topbarMenuSmall]="'hoofdnavigatie-balk-klein' | fromMenu"
        [menu]="'hoofdnavigatie-uitklap' | fromMenu"
        [theme]="menuTheme$()"
      ></app-topbar>
    }

    <!--  [@routerFade]="prepareRoute(outlet)" -->
    <div class="app__router">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    @if (loaded$()) {
      <div class="app__footer">
        <app-footer-socials [socials]="socials$()"></app-footer-socials>
        <app-footer
          [locations]="locations$()"
          [menu]="'footer-menu' | fromMenu"
          [menuEnd]="'footer-bottom-menu' | fromMenu"
          [menuMain]="'hoofdnavigatie-balk-klein' | fromMenu"
          [domain]="domainName"
        ></app-footer>
      </div>
    }
  </div>

  <app-loading-indicator [loading]="loading$()"></app-loading-indicator>

  @if (loaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  }
</div>

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
