import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Slider } from '../models';
import { Observable } from 'rxjs';
import { exampleSlider } from 'src/app/styleguide/exampledata/example-entities';

@Injectable({
  providedIn: 'root',
})
export class SlidersService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  loadSlider(id: number) {
    // TODO remove testcode
    if (id === -1) {
      return this.testSlider(id);
    }
    return this.http.get<Slider>(`${this.BASE_URL}api/slider/${id}`);
  }

  testSlider(id: number): Observable<Slider> {
    return new Observable<Slider>(observer => {
      setTimeout(() => {
        observer.next({
          ...exampleSlider,
          id,
        });
        observer.complete();
      }, 1000);
    });
  }
}
