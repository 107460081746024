import { Routes } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { LocationGuard } from './location/guards';
import { SocialChannelsGuard } from './social-media/guards';
import { menuThemeGuard, resetMenuThemeGuard } from './guards/menu-theme.guard';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, LocationGuard, SocialChannelsGuard, menuThemeGuard];

export const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: 'contentblokken',
    pathMatch: 'prefix',
    loadChildren: () => import('./content-blocks/content-blocks.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
    data: {
      reuseComponent: true,
    },
  },
  {
    path: 'agenda',
    loadChildren: () => import('./events/events.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: 'nieuws',
    loadChildren: () => import('./news/news.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: 'museum/collecties',
    loadChildren: () => import('./museum-collections/museum-collections.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: 'zoeken',
    loadChildren: () => import('./search/search.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.routes').then(m => m.routes),
    canActivate: [...appGuards],
    canDeactivate: [resetMenuThemeGuard],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
