@if (page(); as page) {
  <fstr-link
    cssClasses="button button-link button--xs text-dark fs-5 d-none d-sm-inline-flex mb-2 hover--underline-effect"
    [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink, fragment: page.fragment }"
    [routerLinkActive]="'is--active'"
    [routerLinkActiveOptions]="{ exact: page.url === '/' }"
    [showText]="false"
    (click)="closeOverlay.emit()"
  >
    <span>{{ page.title }}</span>
  </fstr-link>

  <button
    class="button button-link button--xs text-dark fs-5 d-sm-none toggle-button"
    [class.is--active]="!collapsed"
    (click)="collapsed = !collapsed"
  >
    <span>{{ page.title }}</span>

    @if (page.menuItems && page.menuItems.length > 0) {
      <fstr-icon class="toggle-button__icon ms-auto icon-sm" anchor="line-icon-chevron-down" [class.is--rotated]="!collapsed"></fstr-icon>
    }
  </button>

  @if (page.menuItems && page.menuItems.length > 0) {
    <div class="sub-nav-list-container" [class.is--expanded]="!collapsed" [style.--list-height]="listHeight">
      <ul class="sub-nav-list py-2 py-md-0" #list>
        @for (page of page.menuItems | ofMenuType: 'MenuItem'; track page) {
          <li class="sub-nav-list__item">
            <fstr-link
              [cssClasses]="
                'button button-link button--xs  fw-normal hover--underline ' +
                (page.featured ? 'button--primary text-decoration-underline' : 'text-dark')
              "
              [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink, fragment: page.fragment }"
              [routerLinkActive]="'is--active'"
              [routerLinkActiveOptions]="{ exact: page.url === '/' }"
              [showText]="false"
              (click)="closeOverlay.emit()"
            >
              <span class="lh-sm">
                @if (page.label) {
                  <span class="badge text-bg-accent float-end ms-1">{{ page.label }}</span>
                }
                {{ page.title }}
              </span>
            </fstr-link>
          </li>
        }
      </ul>
    </div>
  }
}
