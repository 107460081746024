import {
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  PLATFORM_ID,
  inject,
  effect,
  input,
  output,
  viewChild,
  ElementRef,
  afterNextRender,
  ViewChild,
  computed,
  HostListener,
  ViewEncapsulation,
  ViewChildren,
} from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { auditTime, filter, throttleTime } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { IconComponent, IconModule } from '@teamfoster/sdk/icon';
import { DOCUMENT, NgClass } from '@angular/common';
import { LinkComponent } from '@teamfoster/sdk/button';
import { TopbarNavListComponent } from '../topbar-nav-list/topbar-nav-list.component';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { fromEvent } from 'rxjs';
import { MenuTheme } from '../../models/menu-theme.model';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  standalone: true,
  styleUrls: ['./topbar.component.scss'],
  imports: [
    RouterModule,
    IconComponent,
    LinkComponent,
    TopbarNavListComponent,
    CdkTrapFocus,
    NgClass,
    MatFormField,
    ReactiveFormsModule,
    FormsModule,
    MatInput,
    MatPrefix,
    MatSuffix,
  ],
  //encapsulation: ViewEncapsulation.None,
})
export class TopbarComponent {
  private router = inject(Router);

  loading = input<boolean>(false);
  theme = input<MenuTheme>('accent');
  menuActive = input<boolean>(false);
  logoClass = input<string>('');
  menu = input<fromMenu.MenuItem[]>([]);
  topbarMenu = input<fromMenu.MenuItem[]>([]);
  topbarMenuSmall = input<fromMenu.MenuItem[]>([]);

  toggleOverlay = output<void>();
  closeOverlay = output<void>();
  openOverlay = output<void>();

  @ViewChild('topbar') topbar?: ElementRef;
  @ViewChild('subnav') subnav?: ElementRef;

  private document = inject(DOCUMENT);

  private navigationStart$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));

  topbarMenu$ = computed(() => this.topbarMenu().filter(item => !item.featured));
  topbarFeatured$ = computed(() => this.topbarMenu().filter(item => item.featured));

  routerEffect$ = effect(() => {
    const navigationStart = this.navigationStart$();

    if (this.menuActive() && navigationStart instanceof NavigationStart) {
      //this.closeOverlay.emit();
    }
  });

  topbarNavFitsInView = true;

  scrollEvent = toSignal(
    fromEvent(window, 'scroll').pipe(
      throttleTime(100), // Emit the first event in the given time interval
      auditTime(100) // After the specified time, emit the last event observed
    )
  );

  lastScrollTop = 0;
  scrollDirection: 'up' | 'down' = 'up';
  fixed = false;

  cd = inject(ChangeDetectorRef);

  fb = inject(FormBuilder);
  query = this.fb.control<string>('');

  scrollEventEffect$ = effect(() => {
    const scrollEvent = this.scrollEvent();
    const st = window.scrollY > 0 ? window.scrollY : 0;
    this.scrollDirection = st < this.lastScrollTop ? 'up' : 'down';
    this.lastScrollTop = st;

    this.fixed = this.lastScrollTop > 50;

    this.cd.detectChanges();
  });

  constructor() {
    afterNextRender(() => {
      // Safe to check `scrollHeight` because this will only run in the browser, not the server.
      this.calculateCssVars();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.calculateCssVars();
  }

  calculateCssVars() {
    this.document.documentElement.style.setProperty(
      '--bar-height',
      `${this.topbar?.nativeElement?.getBoundingClientRect()?.height || 0}px`
    );

    this.document.documentElement.style.setProperty(
      '--subnav-height',
      `${this.subnav?.nativeElement?.getBoundingClientRect()?.height || 0}px`
    );
  }
}
