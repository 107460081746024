import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { reducers, effects } from './store';
import { LocationService, LocationConfigService } from './services';
import { LocationConfig } from './models/location-config';

export function ProvideLocationNgrx(config: Partial<LocationConfig>): EnvironmentProviders {
  // using default values for missing properties
  const merged: LocationConfig = {
    apiUrl: 'api/locations',
    ...config,
  };

  return makeEnvironmentProviders([
    provideState('location', reducers),
    provideEffects(effects),
    LocationService,
    {
      provide: LocationConfigService,
      useValue: merged,
    },
  ]);
}
