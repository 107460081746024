import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { reducers, effects } from './store';
import { SlidersService, SliderConfigService } from './services';
import { SliderConfig } from './models/slider-config';

export function ProvideSliderNgrx(config: Partial<SliderConfig>): EnvironmentProviders {
  // using default values for missing properties
  const merged: SliderConfig = {
    apiUrl: 'api/slider',
    ...config,
  };

  return makeEnvironmentProviders([
    provideState('slider', reducers),
    provideEffects(effects),
    SlidersService,
    {
      provide: SliderConfigService,
      useValue: merged,
    },
  ]);
}
