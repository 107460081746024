import { inject, Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as sliderActions from '../actions/sliders.action';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { SlidersService } from '../../services';

export const LoadSliders$ = createEffect(
  (actions$ = inject(Actions), service = inject(SlidersService)) => {
    return actions$.pipe(
      ofType(sliderActions.LoadSlider),
      mergeMap(({ id }) =>
        service.loadSlider(id).pipe(
          map(slider => sliderActions.LoadSliderSuccess({ slider })),
          catchError(error => of(sliderActions.LoadSliderFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
