<footer class="footer section-inset-y bg-secondary text-light">
  <section class="container">
    <div class="row">
      <div class="col-md-12 col-lg-10">
        <h1 class="h3 mb-4">{{ 'footer-nieuwsbrief-titel' | fromDictionary }}</h1>
        <p class="intro">{{ 'footer-nieuwsbrief-intro' | fromDictionary }}</p>
      </div>
      <aside class="col-md-11 col-lg-13 offset-md-1 mt-3 mt-md-0">
        <a [href]="'footer-nieuwsbrief-link' | fromDictionary" class="button button--accent mt-0 mt-md-2" target="_blank">
          <span class="d-none d-sm-inline">{{ 'footer-nieuwsbrief-link-label' | fromDictionary }}</span>
          <span class="d-sm-none">{{ 'footer-nieuwsbrief-link-label-mobiel' | fromDictionary }}</span>
          <fstr-icon anchor="icon-arrow-right"></fstr-icon>
        </a>
        <div class="mt-4">
          <app-social-channel-buttons [title]="'footer-socials-titel' | fromDictionary" [channels]="socials()"></app-social-channel-buttons>
        </div>
      </aside>
    </div>
  </section>
</footer>
