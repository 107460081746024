import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  ElementRef,
  inject,
  Input,
  input,
  OnInit,
  signal,
  viewChild,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getSliderEntities, LoadSlider } from '../store';
import { Slider } from '../models';
import { JsonPipe } from '@angular/common';
import { register, SwiperContainer } from 'swiper/element';
import { LoadingIndicatorComponent } from 'src/app/standalone/components/loading-indicator/loading-indicator.component';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { SwiperOptions } from 'swiper/types';
import { ImageComponent } from '@teamfoster/sdk/image';
import { IconComponent } from '@teamfoster/sdk/icon';

register();

@Component({
  selector: 'app-content-slider',
  standalone: true,
  imports: [JsonPipe, LoadingIndicatorComponent, FromDictionaryPipe, ImageComponent, IconComponent],
  templateUrl: './content-slider.component.html',
  styleUrl: './content-slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentSliderComponent implements OnInit, AfterContentChecked {
  // Inputs with dynamic hook dont work with signals yet
  @Input({ required: true }) id?: number;

  cd = inject(ChangeDetectorRef);
  store = inject(Store);

  swiperEl = viewChild<ElementRef<SwiperContainer>>('swipercontainer');
  swiperThumbEl = viewChild<ElementRef<SwiperContainer>>('swiperThumb');

  swiper = computed(() => {
    if (this.loading$() || !this.swiperEl() || !this.swiperInited()) {
      return;
    }

    return this.swiperEl()?.nativeElement.swiper;
  });

  loadedSliders$ = this.store.selectSignal(getSliderEntities);
  slider$ = computed(() => (this.id !== undefined ? this.loadedSliders$()?.[this.id] : undefined));
  loading$ = computed(() => !this.slider$());

  ready = effect(
    () => {
      if (this.loading$() || !this.swiperEl()) {
        return;
      }
      this.initSwiper();
    },
    { allowSignalWrites: true }
  );

  swiperInited = signal(false);
  swiperIndex$ = signal(0);

  ngOnInit(): void {
    if (this.id) {
      this.store.dispatch(LoadSlider({ id: this.id }));
    }
  }

  ngAfterContentChecked(): void {
    this.initSwiper();
  }

  initSwiper() {
    if (this.swiperInited()) {
      this.swiper()?.update();
      //this.swiperThumbEl()?.nativeElement.swiper?.update();
      return;
    }
    if (!this.slider$()) {
      return;
    }

    const that = this;

    this.swiper()?.slides.length;

    //this.initializeThumbSlider();

    const params: SwiperOptions = {
      //modules: [Autoplay],
      //thumbs: {
      //  swiper: this.swiperThumbEl()?.nativeElement?.swiper,
      //},
      loop: false,
      autoplay: false,
      spaceBetween: 10,
      slidesPerView: 'auto',
      centeredSlides: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      mousewheel: {
        forceToAxis: true,
      },
      injectStyles: [
        `
        :host .swiper {
          overflow:inherit;
        }
        `,
      ],
      breakpoints: {
        0: {
          spaceBetween: 5,
          //centeredSlides: true,
        },
        768: {
          spaceBetween: 10,
          //centeredSlides: false,
        },
      },
      on: {
        realIndexChange: function () {
          that.cd.detectChanges();
        },
        resize: function () {
          that.cd.detectChanges();
        },
        afterInit: function () {
          console.log(that.swiper());
          that.cd.detectChanges();
        },
      },
      // freeMode: true,
    };

    if (this.swiperEl()?.nativeElement) {
      Object.assign(this.swiperEl()!.nativeElement, params);
      this.swiperEl()!.nativeElement.initialize();
      this.swiperInited.set(true);
    }

    this.cd.detectChanges();
  }

  initializeThumbSlider() {
    const thumbParams: SwiperOptions = {
      spaceBetween: 5,
      slidesPerView: 6,
      watchSlidesProgress: true,
      allowTouchMove: false,
      breakpoints: {
        0: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 5,
        },
        850: {
          slidesPerView: 6,
        },
        1000: {
          slidesPerView: 7,
        },
        1300: {
          slidesPerView: 8,
        },
      },
    };

    if (this.swiperThumbEl()?.nativeElement) {
      Object.assign(this.swiperThumbEl()!.nativeElement, thumbParams);
      this.swiperThumbEl()!.nativeElement.initialize();
    }
  }

  get isEnd() {
    return (this.swiper()?.activeIndex || 0) === (this.slider$()?.slides?.length || 0) - 1;
  }

  preventBack(event: any) {
    if (event.wheelDeltaX !== 0) {
      event.preventDefault();
    }
  }
}
