<!--[cdkTrapFocus]="menuActive()"-->

<div
  class="topbar"
  [ngClass]="'direction--' + scrollDirection + ' topbar--' + theme()"
  [class.has--background]="fixed && !menuActive()"
  [class.is--open]="menuActive()"
>
  <div class="topbar__sub-nav py-sm-1 px-3 container" #subnav>
    <ul class="main-nav-list main-nav-list--small">
      @for (page of topbarMenuSmall(); track page) {
        <li class="main-nav-list__item d-none d-sm-block">
          <fstr-link
            cssClasses="button button-link button--xs button--dark fw-normal hover--underline"
            [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink, fragment: page.fragment }"
            [routerLinkActive]="'is--active'"
            [routerLinkActiveOptions]="{ exact: page.url === '/' }"
            [showText]="false"
            (click)="closeOverlay.emit()"
          >
            <span>{{ page.title }}</span>
          </fstr-link>
        </li>
      }

      <li class="ms-auto">
        <a class="button button-link button--primary button--xs" href="/" (click)="closeOverlay.emit()">
          <fstr-icon anchor="line-icon-user"></fstr-icon>
          <span>Inloggen</span>
        </a>
      </li>
      <li class="me-md-2">
        <a class="button button-link button--primary button--xs" [routerLink]="['/zoeken']" (click)="closeOverlay.emit()">
          <fstr-icon anchor="line-icon-search"></fstr-icon>
          <span>Zoeken</span>
        </a>
      </li>
    </ul>
  </div>

  <nav class="topbar__main-nav mt-1 mt-md-2" #topbar>
    <div class="container topbar__main-nav__inner">
      <a [routerLink]="'/'" class="me-2 me-md-4 d-inline-block link-reset" (click)="closeOverlay.emit()">
        <h1 class="d-flex mb-0">
          <fstr-icon class="logo-coda icon-aspect-ratio" anchor="logo-coda" [title]="'Coda'"></fstr-icon>
        </h1>
      </a>

      <ul class="main-nav-list gap-4 flex-grow-1 d-none d-md-flex">
        @for (page of topbarMenu$(); track page) {
          <li class="main-nav-list__item">
            <fstr-link
              cssClasses="button button-link button--dark hover--underline-effect"
              [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink, fragment: page.fragment }"
              [routerLinkActive]="'is--active'"
              [routerLinkActiveOptions]="{ exact: page.url === '/' }"
              [showText]="false"
              (click)="closeOverlay.emit()"
            >
              <span>{{ page.title }}</span>
            </fstr-link>
          </li>
        }
      </ul>

      <ul class="main-nav-list">
        @for (page of topbarFeatured$(); track page; let i = $index) {
          <li [class.ms-auto]="i === 0">
            <fstr-link
              [cssClasses]="'button button--primary' + (i !== 0 || topbarFeatured$().length === 1 ? '' : 'button-outline')"
              [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink, fragment: page.fragment }"
              [routerLinkActive]="'is--active'"
              [routerLinkActiveOptions]="{ exact: page.url === '/' }"
              [showText]="false"
              (click)="closeOverlay.emit()"
            >
              <span>{{ page.title }}</span>
            </fstr-link>
          </li>
        }

        <li [class.ms-auto]="!topbarFeatured$().length">
          <button
            class="button button--light text-decoration-none"
            aria-label="Toon uitgebreid menu"
            [class.is--active]="menuActive()"
            (click)="toggleOverlay.emit()"
          >
            @if (menuActive()) {
              <span>Sluiten</span>
            } @else {
              <span>Menu</span>
            }
            <span class="hamburger" [class.is--active]="menuActive()">
              <span class="hamburger__lines"></span>
            </span>
          </button>
        </li>
      </ul>
    </div>
  </nav>
</div>

@if (menuActive()) {
  <aside class="nav-overlay bg-secondary-100" [class.is--active]="menuActive()" [class.is--fixed]="fixed">
    <div class="container nav-overlay__inner h-sm-100 py-3 py-sm-4 py-lg-5">
      <div class="row h-100">
        <div class="col-md-11 col-lg-9 nav-overlay__sidebar">
          <nav class="sidebar-panel bg-secondary h-100 p-3 p-xxsm-4 p-md-5 p-xl-6">
            <span class="text-accent fw-bold d-block mb-3">Direct naar</span>

            <ul class="featured-nav-list gutter-y-2 gutter-y-sm-3 gutter-y-md-4">
              @for (page of topbarMenuSmall(); track page) {
                <li class="featured-nav-list__item">
                  <fstr-link
                    cssClasses="button button-link button--xs text-light fs-3 fw-bold hover--underline-effect"
                    [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink, fragment: page.fragment }"
                    [routerLinkActive]="'is--active'"
                    [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                    [showText]="false"
                    (click)="closeOverlay.emit()"
                  >
                    <span>{{ page.title }}</span>
                  </fstr-link>
                </li>
              }
            </ul>
          </nav>
        </div>

        <div class="col-24 d-sm-none mt-3 px-3 px-xsm-4">
          <mat-form-field
            appearance="fill"
            class="field-round search-field"
            [formControl]="query"
            ngDefaultControl
            [subscriptSizing]="'dynamic'"
          >
            <fstr-icon class="ms-3 me-2 text-secondary" matPrefix anchor="line-icon-search"></fstr-icon>

            <input matInput placeholder="Jouw zoekterm..." />

            @if (query.value) {
              <button
                matSuffix
                class="button button--accent"
                [routerLink]="['/zoeken']"
                [queryParams]="{ q: query.value }"
                (click)="closeOverlay.emit()"
              >
                <span>Zoeken</span>
              </button>
            }
          </mat-form-field>
        </div>

        <div class="col-md-13 col-lg-15 offset-xl-1 col-xl-14 mt-4 mt-md-0 nav-overlay__list">
          <ul class="nav-list row gutter-y-2 gutter-y-md-4 mt-md-3 px-3 px-xsm-4 px-md-0">
            @for (page of menu(); track page) {
              <li class="nav-list__item col-sm-12 col-lg-8">
                <app-topbar-nav-list [page]="page" (closeOverlay)="closeOverlay.emit()"></app-topbar-nav-list>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  </aside>
}
