import { createAction, props } from '@ngrx/store';
import { Location } from '../../models';

export const LoadLocations = createAction('[Location] Load Locations');
export const LoadLocationsSuccess = createAction('[Location] Load Locations Success', props<{ Location: Location[] }>());
export const LoadLocationsFail = createAction('[Location] Load Locations Fail', props<{ error: any }>());

// export const LoadLocationById = createAction('[Location] Load Location by Id', props<{ id: number }>());
// export const LoadLocationByIdSuccess = createAction('[Location] Load Location by Id Success', props<{ Item: Location }>());
// export const LoadLocationByIdFail = createAction('[Location] Load Location by Id Fail', props<{ error: any }>());
