import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromLocations from '../reducers/location.reducer';

export const getLocationsState = createSelector(fromFeature.getLocationState, (state: fromFeature.LocationState) => state.Location);

export const getLocationEntities = createSelector(getLocationsState, fromLocations.getLocationEntities);

export const getAllLocations = createSelector(getLocationEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getAllLocationsWithOrderedDepartments = createSelector(getAllLocations, locations => {
  return locations.map(location => {
    return {
      ...location,
      departments: [...location.departments].sort((a, b) => a.order - b.order),
    };
  });
});

export const getLocationsLoading = createSelector(getLocationsState, fromLocations.getLocationsLoading);
export const getLocationsLoaded = createSelector(getLocationsState, fromLocations.getLocationsLoaded);
export const getLocationsError = createSelector(getLocationsState, fromLocations.getLocationsError);
