<nav class="d-flex gap-3 align-items-center flex-wrap">
  @if (title()) {
    <h2 class="h6 mb-0">{{ title() }}</h2>
  }
  <ul class="follow-button-list d-flex gap-2 social-channels__list">
    @for (item of channels(); track item) {
      <li class="follow-button-list__item">
        <a [href]="item.url" target="_blank" class="button button-icon button--light">
          <span class="sr-only">{{ item.title }}</span>

          @if (!item.imageUrl && item.icon) {
            <fstr-icon [anchor]="item.icon" [title]="item.title"></fstr-icon>
          }
        </a>
      </li>
    }
  </ul>
</nav>
