import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, Input, OnInit, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { Observable } from 'rxjs';
import { ContentPage } from 'src/app/content/models';
import { LoadLocations, getAllLocations } from 'src/app/location/store';
import { JsonLdComponent } from 'src/app/seo/components/json-ld/json-ld.component';
import { SocialChannel } from 'src/app/social-media/models';
import { Department, Location } from 'src/app/location/models';
import { LinkComponent } from '@teamfoster/sdk/button';
import { IconComponent } from '@teamfoster/sdk/icon';
import { SocialChannelButtonsComponent } from 'src/app/social-media/components/social-channel-buttons/social-channel-buttons.component';

@Component({
  selector: 'app-footer-socials',
  templateUrl: './footer-socials.component.html',
  standalone: true,
  styleUrls: ['./footer-socials.component.scss'],
  imports: [FromDictionaryPipe, JsonLdComponent, JsonPipe, AsyncPipe, LinkComponent, IconComponent, SocialChannelButtonsComponent],
})
export class FooterSocialsComponent {
  private lang = inject(LanguageService);
  private dict = new FromDictionaryPipe(this.lang);

  socials = input<SocialChannel[]>([]);
}
