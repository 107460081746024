import { createReducer, on } from '@ngrx/store';
import { Location } from '../../models';
import * as pageActions from '../actions/location.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface LocationState {
  entities: { [id: number]: Location };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: LocationState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const LocationReducer = createReducer(
  initialState,

  on(pageActions.LoadLocations, (state: LocationState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadLocationsSuccess, (state: LocationState, { Location }) => {
    const entities = ToEntities(Location, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadLocationsFail, (state: LocationState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getLocationEntities = (state: LocationState) => state.entities;
export const getLocationsLoading = (state: LocationState) => state.loading;
export const getLocationsLoaded = (state: LocationState) => state.loaded;
export const getLocationsError = (state: LocationState) => state.error;
