<footer class="footer section-inset-y">
  <h1 class="sr-only">Footer</h1>

  <div class="container">
    @if (menu(); as menu) {
      <div class="row">
        @for (item of menu | ofMenuType: 'MenuItem'; track item) {
          <nav class="mb-4 col-xsm-12 col-lg-6 footer__nav">
            <h2 class="footer__nav__title mb-3 mb-lg-4 text-primary">{{ item.title }}</h2>

            @if (item.label === 'locations') {
              @if (centralLocation$(); as location) {
                <ul class="footer__nav__list">
                  @for (department of location.departments; track department) {
                    <li class="mb-2 mb-md-3">
                      <strong class="d-block">{{ department.name }}</strong>
                      <em>{{ getOpeningTime(department) }}</em>
                    </li>
                  }
                </ul>
              }
              <app-json-ld [json]="schema()"></app-json-ld>
            }
            <ul class="footer__nav__list">
              @for (link of item.menuItems | ofMenuType: 'MenuItem'; track link) {
                <li class="mb-2 mb-md-3">
                  <fstr-link
                    [button]="{ text: link.title, url: link.url || '', routerLink: link.routerLink, fragment: link.fragment }"
                    [cssClasses]="'button button-link button--dark'"
                  ></fstr-link>
                </li>
              }
            </ul>
          </nav>
        }
      </div>
    }

    <section class="footer__end-nav d-flex flex-wrap mt-5 gap-3 justify-content-start justify-content-lg-between">
      @if (menuMain(); as menuMain) {
        <nav class="">
          <ul class="footer__nav-list d-flex gap-3 flex-wrap">
            @for (link of menuMain; track link) {
              <li>
                <fstr-link
                  [button]="{ text: link.title, url: link.url || '', routerLink: link.routerLink, fragment: link.fragment }"
                  [cssClasses]="'button button-link button--dark'"
                ></fstr-link>
              </li>
            }
          </ul>
        </nav>
      }
      @if (menuEnd(); as menuEnd) {
        <nav class="">
          <ul class="footer__nav-list d-flex gap-3 flex-wrap">
            @for (link of menuEnd; track link) {
              <li>
                <fstr-link
                  [button]="{ text: link.title, url: link.url || '', routerLink: link.routerLink, fragment: link.fragment }"
                  [cssClasses]="'button button-link button--dark'"
                ></fstr-link>
              </li>
            }
            <li class="footer__nav-list__item">
              <button (click)="openCookie()" class="button button-link button--dark">{{ 'cookie-settings' | fromDictionary }}</button>
            </li>
          </ul>
        </nav>
      }
    </section>
  </div>
</footer>
