import * as fromLocation from './location.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'location';

export interface LocationState {
  Location: fromLocation.LocationState;
}

export const reducers: ActionReducerMap<LocationState> = {
  Location: fromLocation.LocationReducer,
};

export const getLocationState = createFeatureSelector<LocationState>(featureKey);

export const metaReducers: MetaReducer<LocationState>[] = !environment.production ? [] : [];
