import { Injectable, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/location.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { LocationService } from '../../services';
import { Go } from 'src/app/store';

export const LoadLocations$ = createEffect(
  (actions$ = inject(Actions), service = inject(LocationService)) => {
    return actions$.pipe(
      ofType(actions.LoadLocations),
      switchMap(() =>
        service.loadLocations().pipe(
          map(Location => actions.LoadLocationsSuccess({ Location })),
          catchError(error => of(actions.LoadLocationsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

// export const LoadLocationById$ = createEffect(
//   (actions$ = inject(Actions), service = inject(LocationService)) => {
//     return actions$.pipe(
//       ofType(actions.LoadLocationById),
//       switchMap(({ id }) =>
//         service.getLocationById(id).pipe(
//           map(Item => actions.LoadLocationByIdSuccess({ Item })),
//           catchError(error => of(actions.LoadLocationByIdFail({ error })))
//         )
//       )
//     );
//   },
//   { functional: true }
// );

// export const LoadLocationByIdFail$ = createEffect(
//   (actions$ = inject(Actions)) => {
//     return actions$.pipe(
//       ofType(actions.LoadLocationByIdFail),
//       map(({ error }) => {
//         return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
//       })
//     );
//   },
//   { functional: true }
// );
