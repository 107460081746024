import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationConfig, Location } from '../models';
import { LocationConfigService } from './location-config.service';
@Injectable()
export class LocationService {
  public location: { [identifier: string]: Location } = {};

  private get _apiUrl() {
    if (!this.config.apiUrl) return `${this.BASE_URL || ''}api/locations`;
    if (this.isAbsolute(this.config.apiUrl)) {
      return this.config.apiUrl;
    }

    return `${this.BASE_URL || ''}${this.config.apiUrl}`;
  }

  constructor(
    private Http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string,
    @Inject(LocationConfigService) private config: LocationConfig
  ) {}

  loadLocations() {
    return this.Http.get<Location[]>(this._apiUrl);
  }

  init() {}

  isAbsolute(url: string) {
    var pattern = /^https?:\/\//i;
    return pattern.test(url);
  }
}
