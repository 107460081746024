import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, afterNextRender, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LinkComponent } from '@teamfoster/sdk/button';
import { IconComponent } from '@teamfoster/sdk/icon';
import { MenuItem, OfMenuTypePipe } from '@teamfoster/sdk/menu-ngrx';

@Component({
  selector: 'app-topbar-nav-list',
  standalone: true,
  imports: [LinkComponent, IconComponent, RouterLink, OfMenuTypePipe],
  templateUrl: './topbar-nav-list.component.html',
  styleUrl: './topbar-nav-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarNavListComponent {
  page = input<MenuItem>();
  closeOverlay = output<void>();

  @ViewChild('list') list?: ElementRef;
  collapsed = true;
  listHeight = '100px';

  constructor() {
    afterNextRender(() => {
      this.listHeight = `${this.list?.nativeElement.getBoundingClientRect().height}px`;
    });
  }
}
