import { isPlatformServer } from '@angular/common';
import { Component, Optional, Inject, InjectionToken, PLATFORM_ID, OnInit } from '@angular/core';
import { RESPONSE } from '../express.tokens';
import { Router, RouterLink } from '@angular/router';
import { Response } from 'express';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-404',
  standalone: true,
  imports: [FromDictionaryPipe, RouterLink],
  template: `
    <article class="error-404 pt-topbar bg-accent">
      <div class="container text-center section-inset-y">
        <h1>{{ 'error-404-title' | fromDictionary }}</h1>
        <p class="text-max-width mx-auto">{{ 'error-404-text' | fromDictionary }}</p>
        <div class="error-404__content text-center mt-4">
          <a [routerLink]="'/'" class="button button--primary">Terug naar home</a>
        </div>
      </div>
    </article>
  `,
})
export class NotFoundComponent implements OnInit {
  code = 404;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Optional() @Inject(RESPONSE) private response: Response,
    private router: Router
  ) {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      if (this.response) {
        this.response.status(this.code);
      }
    }
  }
}
